const Footer = () => {
  return (
    <div id="footer" className="dashboardElement">
      This is the footer.
      Not Facebook at all.
      All rights NOT reserved AT ALL.
    </div>
  )
} 

export default Footer;